const birds = document.getElementsByClassName('bird');
Array.from(birds).forEach(function (bird) {
  bird.addEventListener('click', killBird, false);
  bird.addEventListener('touchstart', killBird, false);
});

function killBird(event) {
  event.preventDefault();
  let bird = event.target;
  bird.parentNode.removeChild(bird);
}
